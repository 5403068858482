import { ShopProvider } from '@zola/zola-ui/src/contexts/ShopContext/ShopProvider';

import { UserContext } from '@/actions/UserActions.type';
import { ThemeSelector } from '@/components/shop/ThemeSelector/ThemeSelector';

import { RegistryShoppingProvider } from './RegistryContext/RegistryShoppingProvider';

export const RegistryShoppingPage: React.FC<{
  isBaby: boolean;
  userContext: UserContext | null;
}> = ({ isBaby, userContext, children }) => {
  return (
    <ShopProvider store={isBaby ? 'baby' : 'wedding'}>
      <ThemeSelector>
        <RegistryShoppingProvider userContext={userContext}>{children}</RegistryShoppingProvider>
      </ThemeSelector>
    </ShopProvider>
  );
};
