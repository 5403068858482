import { createEnvironmentFlags, getFlag } from '@zola-helpers/client/dist/es/featureFlags';
import getEnvironment from '@zola-helpers/client/dist/es/util/environment';

export const FLAGS = {
  datadog: createEnvironmentFlags(false, true, true), // Toggle datadog monitoring
  giftImportBrandExtraction: createEnvironmentFlags(true, true, false),
  pushSignupIntoCustomization: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/PE-980
  debugPaymentIntents: createEnvironmentFlags(false, false, false),
  shippingZonesV2: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/PE-1400
  webBaby: createEnvironmentFlags(true, true, false),
  enableDGCFlow: createEnvironmentFlags(true, true, true),
  giftTrackerRefresh: createEnvironmentFlags(true, true, false), // https://newamsterdamlabs.atlassian.net/browse/PE-1777
  paymentIntentQuickCheckoutUpgrade: createEnvironmentFlags(true, false, false), // https://newamsterdamlabs.atlassian.net/browse/PE-1702
};

const get = (flag: keyof typeof FLAGS): boolean => getFlag(FLAGS, flag);

export default {
  get,
  getEnvironment,
};
