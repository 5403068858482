import { Component, CSSProperties } from 'react';

import PreauthNavSkeletonLoader from '@zola/zola-ui/src/components/PreauthNavSkeletonLoader';
import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQuery';
import {
  getPostAuthNav3Height,
  getPostAuthNav3HeightMobile,
} from '@zola-helpers/client/dist/es/nav/getPostAuthNav3Height';

import cx from 'classnames';
import _debounce from 'lodash/debounce';
import _isEqual from 'lodash/isEqual';
import dynamic from 'next/dynamic';
import Sticky from 'react-stickynode';

import type { UserContext } from '@/actions/UserActions.type';
import featureFlags from '@/util/featureFlags';

import initNavData from './initNavData';

const UnifiedNavHtmlLoader = dynamic(
  () => import('@/components/common/header/UnifiedNavHtmlLoader'),
  { ssr: false }
);
// make sure this website nav gets loaded in a <StickyContainer/>

const getNavHeight = (
  user: UserContext | undefined,
  disableSecondary?: boolean,
  isWeddingBoutiquePage?: boolean,
  isBaby?: boolean
): { desktopHeight: number; mobileHeight: number } => {
  const isGuest = user ? Boolean(user.is_guest) : true;
  const hasSecondaryData = !isWeddingBoutiquePage && !disableSecondary;
  const hasTertiaryData = !disableSecondary;

  const desktopPreAuthHeight = disableSecondary ? 133 : 189;
  let desktopHeight = isGuest
    ? desktopPreAuthHeight
    : getPostAuthNav3Height({
        hasSecondaryData,
        hasTertiaryData,
        hasAnnouncementBar: true,
        isMultiAnnouncementBar: true,
      });

  const mobilePreAuthHeight = disableSecondary ? 56 : 108;
  let mobileHeight = isGuest
    ? mobilePreAuthHeight
    : getPostAuthNav3HeightMobile({
        hasSecondaryData,
        hasTertiaryData,
      });

  if (isBaby) {
    desktopHeight = hasSecondaryData ? 184 : 128;
    mobileHeight = hasSecondaryData ? 136 : 96;
  }

  return {
    desktopHeight,
    mobileHeight,
  };
};

export type UnifiedNavStateProps = {
  user?: UserContext;
};

export type UnifiedNavDispatchProps = {
  fetchUserContext: () => Promise<void | UserContext>;
  fetchNav: (legacyBaby: boolean) => Promise<string>;
  fetchCategories: () => Promise<void>;
  fetchWeddingShopCategories: () => Promise<void>;
};

export type UnifiedNavPropsFromConnect = UnifiedNavStateProps & UnifiedNavDispatchProps;

export type UnifiedNavComponentProps = {
  isHidden?: boolean;
  activePrimaryLink?: string;
  activeSecondaryLink?: string;
  activeTertiaryLink?: string;
  user?: UserContext;
  fetchUserContext: () => Promise<void | UserContext>;
  fetchNav: (legacyBaby: boolean) => Promise<string>;
  styleOverride?: {
    height: number;
    backgroundColor: string;
    zIndex: number;
  };
  fetchCategories: () => Promise<void>;
  fetchWeddingShopCategories: () => Promise<void>;
  showPromo?: boolean;
  disablePrimaryNavCollapse?: boolean;
  disableSecondary?: boolean;
  isBaby?: boolean;
  isWeddingBoutiquePage?: boolean;
  className?: string;
};

type UnifiedNavV2Props = UnifiedNavPropsFromConnect & UnifiedNavComponentProps;

type UnifiedNavV2State = {
  nav: string;
  desktopHeight: number;
  mobileHeight: number;
};

class UnifiedNavV2 extends Component<UnifiedNavV2Props, UnifiedNavV2State> {
  static defaultProps = {
    user: undefined,
    // eslint-disable-next-line react/default-props-match-prop-types
    showPromo: false,
    disablePrimaryNavCollapse: false,
    disableSecondary: false,
  };

  constructor(props: UnifiedNavV2Props) {
    super(props);
    const navHeights = getNavHeight(
      props.user,
      props.disableSecondary,
      props.isWeddingBoutiquePage,
      props.isBaby
    );
    this.state = {
      nav: '',
      desktopHeight: navHeights.desktopHeight,
      mobileHeight: navHeights.mobileHeight,
    };

    this.refreshNavHeight = _debounce(this.refreshNavHeight.bind(this), 200);
    this.handleInitNavData = _debounce(this.handleInitNavData.bind(this), 200);
  }

  componentDidMount(): void {
    const { fetchNav } = this.props;

    window.addEventListener('NAV_LOADED', this.handleInitNavData);
    window.addEventListener('resize', this.refreshNavHeight);

    fetchNav(!featureFlags.get('webBaby'))
      .then((nav: string): void => {
        this.setState({ nav });
      })
      .catch(() => null);
  }

  componentDidUpdate(prevProps: UnifiedNavV2Props): void {
    const { user, activePrimaryLink } = this.props;
    const { user: prevUser, activePrimaryLink: prevActivePrimaryLink } = prevProps;
    if (
      (!_isEqual(user, prevUser) && user) ||
      !_isEqual(activePrimaryLink, prevActivePrimaryLink)
    ) {
      this.refreshNavHeight();
      this.handleInitNavData();
    }
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.refreshNavHeight);
    window.removeEventListener('NAV_LOADED', this.handleInitNavData);
  }

  handleInitNavData(): void {
    const {
      user,
      showPromo,
      fetchUserContext,
      fetchCategories,
      fetchWeddingShopCategories,
      activePrimaryLink,
      activeSecondaryLink,
      activeTertiaryLink,
      disablePrimaryNavCollapse,
      disableSecondary,
      isBaby,
      isWeddingBoutiquePage,
    } = this.props;

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    initNavData({
      user,
      showPromo,
      fetchUserContext,
      fetchCategories,
      fetchWeddingShopCategories,
      activePrimaryLink,
      activeSecondaryLink,
      activeTertiaryLink,
      disablePrimaryNavCollapse,
      disableSecondary,
      isBaby,
      isWeddingBoutiquePage,
    });
  }

  refreshNavHeight(): void {
    const { desktopHeight, mobileHeight } = this.state;
    const { disableSecondary, isBaby, isWeddingBoutiquePage, user } = this.props;
    const newNavHeight = getNavHeight(user, disableSecondary, isWeddingBoutiquePage, isBaby);
    if (desktopHeight !== newNavHeight.desktopHeight) {
      this.setState({ desktopHeight: newNavHeight.desktopHeight });
    }
    if (mobileHeight !== newNavHeight.mobileHeight) {
      this.setState({ mobileHeight: newNavHeight.mobileHeight });
    }
  }

  render(): JSX.Element {
    const { styleOverride, className, isHidden, disableSecondary, showPromo } = this.props;
    const hiddenStyles: CSSProperties = isHidden ? { position: 'absolute', left: '-99999px' } : {};
    const { nav, mobileHeight, desktopHeight } = this.state;
    const defaultStyles = {
      zIndex: 500,
      transform: 'none',
    };
    return (
      <div
        className={cx('unified-nav', className)}
        style={{ ...defaultStyles, ...styleOverride, ...hiddenStyles }}
      >
        <Sticky innerZ={500} enableTransforms={false}>
          <PreauthNavSkeletonLoader showPromoBar={showPromo} showSecondaryRow={!disableSecondary} />
          <UnifiedNavHtmlLoader html={nav} />
          {process.env.NODE_ENV !== 'test' && (
            <style
              dangerouslySetInnerHTML={{
                __html: `
                .unified-nav {
                  height: ${mobileHeight}px;
                  ${MEDIA_QUERY.DESKTOP} {
                    height: ${desktopHeight}px;
                  }
                }
              `.replace(/\s+/g, ' '),
              }}
            />
          )}
        </Sticky>
      </div>
    );
  }
}

export default UnifiedNavV2 as unknown as React.ComponentType<UnifiedNavV2Props>;
