import { createContext, useContext } from 'react';

import type {
  AddCashItemRequest,
  AddThirdPartyCashItemRequest,
  BaseAccountRequest,
  CashRegistryItemView,
  ManagedBankRequest,
  WBankAccountView,
  WManagedAccountView,
  WManagedBankAccountView,
  WRegistryCollectionItemView,
} from '@zola/svc-web-api-ts-client';

import { AddToRegistryRequest } from '@/actions/RegistryCollectionActions';
import { RegistryItemDetails } from '@/types/RegistryItemDetails';

export type UpdateRegistryItemFn = (
  collectionItemId?: string,
  request?: RegistryItemDetails
) => Promise<void>;
export type DeleteRegistryItemFn = (itemId: string) => Promise<void>;

export type AddCashFundRequest = Omit<
  AddCashItemRequest,
  'registry_id' | 'user_object_id' | 'platform'
>;

export type AddCashFundFn = (
  request: AddCashFundRequest
) => Promise<CashRegistryItemView | undefined>;

export type AddExternalCashFundFn = (
  request: Omit<AddThirdPartyCashItemRequest, 'user_object_id' | 'platform' | 'registry_id'>
) => Promise<WRegistryCollectionItemView | undefined>;

export type ManageRegistryCollectionContextValue = {
  /**
   * Adds an item to the registry.  This does not handle the registry not being
   * setup yet.  An check must be made to determine if the registry has been created
   * and if not, redirect to onboarding to create the registry.
   */
  addToRegistry: (
    data: AddToRegistryRequest,
    productShippingZones?: string[],
    productShippingExcludedStates?: string[]
  ) => Promise<WRegistryCollectionItemView | undefined>;

  updateRegistryItem: UpdateRegistryItemFn;
  updateExternalRegistryItem: (
    collectionItemId?: string,
    requestPayload?: RegistryItemDetails
  ) => Promise<void>;

  deleteRegistryItem: DeleteRegistryItemFn;

  addCashFundToRegistry: AddCashFundFn;
  updateCashFund: (
    collectionItemId?: string,
    requestPayload?: RegistryItemDetails
  ) => Promise<void>;

  addExternalCashFundToRegistry: AddExternalCashFundFn;
  updateThirdPartyCashFund: (
    collectionItemId?: string,
    requestPayload?: RegistryItemDetails
  ) => Promise<void>;
  checkRegistryBankAccount?: () => Promise<WBankAccountView>;
  createRegistryManagedAccount?: (
    request: Partial<BaseAccountRequest>
  ) => Promise<WManagedAccountView>;
  addManagedBankAccount?: (request: ManagedBankRequest) => Promise<WManagedBankAccountView>;
  checkRegistryManagedAccount?: () => Promise<WManagedAccountView>;
};

export const ManageRegistryCollectionContext = createContext<
  ManageRegistryCollectionContextValue | undefined
>(undefined);

export const useManageRegistryCollectionContext = (): ManageRegistryCollectionContextValue => {
  const context = useContext(ManageRegistryCollectionContext);
  if (!context) {
    throw new Error(
      'useManageRegistryCollectionContext must be used within a ManageRegistryCollection Provider'
    );
  }
  return context;
};
