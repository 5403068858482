import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';

import PropTypes from 'prop-types';

import Swatch from './Swatch';
import './TileSwatchList.less';

export default function TileSwatchList({
  activeProductLookId,
  swatches,
  limit,
  onSwatchChange,
  onShowMoreCallback,
  showMoreAsNumber,
}) {
  const hasMoreLink = swatches.length > limit;

  function createSwatch(swatchObj, swatchHandler, activeBool, i) {
    const { image_url: imageUrl, label, product_look_id: id } = swatchObj;
    return (
      <Swatch
        key={i}
        imageUrl={imageUrl}
        label={label}
        id={id}
        isActive={activeBool}
        handleSwatchChange={swatchHandler}
        isInteractive={!!activeProductLookId}
      />
    );
  }
  return (
    <div className="swatches">
      {swatches.slice(0, limit).map((swatch, i) => {
        const isActive = activeProductLookId && swatch.product_look_id === activeProductLookId;
        const handleSwatchChange = () => onSwatchChange(swatch.product_look_id);
        return createSwatch(swatch, handleSwatchChange, isActive, i);
      })}
      {hasMoreLink && !showMoreAsNumber && (
        <LinkV2
          role="button"
          noUnderline
          onClick={onShowMoreCallback}
          className="swatches-more-link"
        >
          more
        </LinkV2>
      )}
      {hasMoreLink && showMoreAsNumber && (
        <LinkV2
          role="button"
          noUnderline
          subtle
          onClick={onShowMoreCallback}
          className="swatches-more-link"
        >
          +{swatches.length - limit}
        </LinkV2>
      )}
    </div>
  );
}

TileSwatchList.propTypes = {
  activeProductLookId: PropTypes.string,
  swatches: PropTypes.arrayOf(PropTypes.shape({})),
  limit: PropTypes.number,
  onSwatchChange: PropTypes.func,
  onShowMoreCallback: PropTypes.func,
  showMoreAsNumber: PropTypes.bool,
};
