import { useShopContext } from '@zola/zola-ui/src/contexts/ShopContext/ShopContext';

import dynamic from 'next/dynamic';

import { UserContext } from '@/actions/UserActions.type';
import { ManageRegistryCollectionProvider } from '@/contexts/registry/ManageRegistryCollectionContext/ManageRegistryCollectionProvider';

import { useRegistryShoppingContext } from './RegistryShoppingContext';

const BabyRegistryShoppingProvider = dynamic(() => import('./BabyRegistryShoppingProvider'), {
  loading: () => <></>,
});

const WeddingRegistryShoppingProvider = dynamic(() => import('./WeddingRegistryShoppingProvider'), {
  loading: () => <></>,
});

const ManageRegistryCollectionAdapter: React.FC<{ isBaby: boolean }> = (props) => {
  const { registryId } = useRegistryShoppingContext();
  return <ManageRegistryCollectionProvider registryObjectId={registryId} {...props} />;
};

const StoreSpecificShoppingProvider: React.FC<{
  userContext: UserContext | null;
  isBaby: boolean;
}> = ({ isBaby, ...rest }) => {
  return isBaby ? (
    <BabyRegistryShoppingProvider {...rest} />
  ) : (
    <WeddingRegistryShoppingProvider {...rest} />
  );
};

export const RegistryShoppingProvider: React.FC<{ userContext: UserContext | null }> = ({
  userContext,
  children,
}) => {
  const { isBaby } = useShopContext();

  return (
    <StoreSpecificShoppingProvider isBaby={isBaby} userContext={userContext}>
      <ManageRegistryCollectionAdapter isBaby={isBaby}>{children}</ManageRegistryCollectionAdapter>
    </StoreSpecificShoppingProvider>
  );
};
