import dynamic from 'next/dynamic';

const BabyManageRegistryCollectionProvider = dynamic(
  () => import('./ManageBabyRegistryCollectionProvider'),
  {
    loading: () => <></>,
  }
);

const WeddingManageRegistryCollectionProvider = dynamic(
  () => import('./ManageWeddingRegistryCollectionProvider'),
  {
    loading: () => <></>,
  }
);

export const ManageRegistryCollectionProvider: React.FC<{
  registryObjectId: string | null;
  isBaby: boolean;
}> = ({ isBaby, ...rest }) => {
  return isBaby ? (
    <BabyManageRegistryCollectionProvider {...rest} />
  ) : (
    <WeddingManageRegistryCollectionProvider {...rest} />
  );
};
