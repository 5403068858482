import NAV_DATA from '@zola-helpers/client/dist/es/constants/navConstants';
import { clientIsBaby } from '@zola-helpers/client/dist/es/util/clientIsBaby';

import { connect } from 'react-redux';

import { AppDispatch, RootState } from '@/store';

import UnifiedNavV2, { UnifiedNavDispatchProps, UnifiedNavStateProps } from './UnifiedNavV2';
import {
  getBabyCategories,
  getCategories,
  getNav,
  getWeddingShopCategories,
  getBabyNav,
} from '../../../../actions/NavActions';
import * as UserActions from '../../../../actions/UserActions';
import { getUserContext } from '../../../../selectors/userSelectors';

const mapStateToProps = (state: RootState): UnifiedNavStateProps => ({
  user: getUserContext(state),
});

const mapDispatchToProps = (dispatch: AppDispatch): UnifiedNavDispatchProps => ({
  fetchNav: (legacyBaby: boolean) => dispatch(clientIsBaby() ? getBabyNav(legacyBaby) : getNav()),
  fetchCategories: () => dispatch(clientIsBaby() ? getBabyCategories() : getCategories()),
  fetchWeddingShopCategories: () => dispatch(getWeddingShopCategories()),
  fetchUserContext: () => dispatch(UserActions.getUserContext()),
});

const connector = connect(mapStateToProps, mapDispatchToProps)(UnifiedNavV2);
export default connector;

export { NAV_DATA };
