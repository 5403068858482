import { FeatureFlagsProductType } from '@zola-helpers/client/dist/es/constants/navConstants';
import { publishFeatureFlagOverrides } from '@zola-helpers/client/dist/es/featureFlags';
import { getBoutiqueNavItems } from '@zola-helpers/client/dist/es/nav/boutiqueNavData';
import {
  getRegistryNavItems,
  getRegistryQuickLinks,
  getRegistrySecondaryNavItems,
} from '@zola-helpers/client/dist/es/nav/registryNavData';

import experimentFlags, { HOLIDAY_SHOPS } from '../../../../util/experimentFlags';
import { FLAGS } from '../../../../util/featureFlags';

/**
 * Maps correct titles for baby registry nav items that aren't worth updating in db at this moment.
 * Will probably make sense to move to zola-helpers if/once we need secondary nav on web-baby pages.
 */
const BABY_REGISTRY_NAV_TITLE_OVERRIDES = {
  'new-arrivals': 'New',
  'nursing-and-pumping': 'Nursing',
  'baby-bedding': 'Bedding',
  'diapering-and-potty': 'Diapering',
  'baby-activity-and-play': 'Toys & Play',
  'experiences-and-gift-cards': 'Experiences',
};
const BABY_REGISTRY_NAV_POSITIONING_OVERRIDES = {
  dockLeft: {
    gear: true,
    nursery: true,
  },
  dockRight: {
    'baby-activity-and-play': true,
    'experiences-and-gift-cards': true,
  },
};
const mapBabyRegistryNavItems = (data) =>
  data.map(({ identifier, title, ...rest }) => ({
    identifier,
    title: BABY_REGISTRY_NAV_TITLE_OVERRIDES[identifier] || title,
    dockLeft: BABY_REGISTRY_NAV_POSITIONING_OVERRIDES.dockLeft[identifier] || false,
    dockRight: BABY_REGISTRY_NAV_POSITIONING_OVERRIDES.dockRight[identifier] || false,
    ...rest,
  }));

const initNavData = ({
  user,
  showPromo,
  fetchUserContext,
  fetchCategories,
  fetchWeddingShopCategories,
  activePrimaryLink,
  activeSecondaryLink,
  activeTertiaryLink,
  disablePrimaryNavCollapse,
  disableSecondary,
  isBaby,
  isWeddingBoutiquePage,
}) => {
  const fetchCategoriesAction = isWeddingBoutiquePage
    ? fetchWeddingShopCategories
    : fetchCategories;

  return Promise.all([!user ? fetchUserContext() : Promise.resolve(user), fetchCategoriesAction()])
    .then(([userContextResponse, categoriesResponse]) => {
      let userContext;
      let categories;
      let secondaryData;
      let tertiaryData;
      let quickLinks;

      if (userContextResponse instanceof Error) {
        userContext = null;
      } else {
        userContext = userContextResponse;
      }
      if (categoriesResponse instanceof Error) {
        categories = [];
      } else {
        categories = categoriesResponse;
      }

      const isGuest = userContext.is_guest;
      const showHolidayData = experimentFlags.getFlag(HOLIDAY_SHOPS, userContext);

      // Secondary nav
      if (!disableSecondary) {
        if (isWeddingBoutiquePage) {
          secondaryData = isGuest ? getBoutiqueNavItems(userContext, categories) : [];
        } else if (isBaby) {
          secondaryData = mapBabyRegistryNavItems(
            getRegistryNavItems(user, categories, false, false, true)
          );
        } else {
          secondaryData = isGuest
            ? getRegistryNavItems(user, categories, showHolidayData, false, false)
            : getRegistrySecondaryNavItems(userContext);
        }
      }

      // Tertiary nav
      if (!isGuest && !disableSecondary && !isBaby) {
        if (isWeddingBoutiquePage) {
          tertiaryData = getBoutiqueNavItems(userContext, categories);
        } else {
          const registrySlug = userContext?.has_registry
            ? userContext?.user_role_account_weddings?.[0].slug
            : undefined;
          quickLinks = getRegistryQuickLinks(registrySlug);
          tertiaryData = getRegistryNavItems(user, categories, showHolidayData, false, isBaby);
        }
      }

      window.dispatchEvent(
        new CustomEvent('INIT_NAV_DATA', {
          detail: {
            secondaryData,
            userContext,
            showPromo,
            activePrimaryLink,
            activeSecondaryLink,
            activeTertiaryLink,
            disablePrimaryNavCollapse,
            tertiaryData,
            quickLinks,
          },
        })
      );

      publishFeatureFlagOverrides(FLAGS, FeatureFlagsProductType.WEB_REGISTRY);

      return [userContextResponse, userContextResponse];
    })
    .catch((e) => {
      // eslint-disable-next-line no-console, no-restricted-syntax
      console.log('Error fetching nav data', e);
    });
};

export default initNavData;
